<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="card pt-2 p-2">
              <div class="row">
                <div class="col-md-8" v-if="formEnvio.tipo_producto_id == 1">
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA UNICA PARA TRANSPORTAR PETROLEO CRUDO
                      <br />PETROMINERALES COLOMBIA CORP. SUCURSAL COLOMBIA
                    </strong>
                  </h3>
                </div>
                <div class="col-md-8" v-else>
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA PARA TRANSPORTAR PRODUCTOS DERIVADOS DEL PETROLEO
                      <br />FRONTERA ENERGY COLOMBIA CORP.
                    </strong>
                  </h3>
                </div>
                <div class="col-md-2 float-md-right">
                  <h6>
                    <span class="badge badge-success" v-if="estadoGuia == 1"
                      >Estado: Disponible</span
                    >
                    <span class="badge badge-primary" v-if="estadoGuia == 2"
                      >Estado: Usada</span
                    >
                    <span class="badge badge-warning" v-if="estadoGuia == 6"
                      >Estado: Sin Imprimir</span
                    >
                  </h6>
                </div>
                <div class="col-md-2" v-if="accion == 'Cambiar'">
                  <span class="badge badge-warning"
                    >N° GUIA ANTERIOR:<br />
                    {{ formEnvio.guia.numero }}-{{
                      formEnvio.guia.digito_verificacion
                    }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-4">
                  <img
                    src="/img/frontera-pegaso-ermes.png"
                    style="width: 280px"
                  />
                </div>
                <div class="col-md-8">
                  <div class="row ml-5">
                    <div class="col-md-2 text-center">
                      <label for>TERCERO</label>
                      <input
                        v-model="tercero"
                        type="checkbox"
                        value="1"
                        class="form-control form-control-sm"
                        @change="buscarGuiaInput()"
                        :disabled="formEnvio.tipo_act === 4"
                      />
                    </div>
                    <div class="col-md-4" v-if="accion != 'Cambiar'">
                      <label for="nGuia">N° GUIA</label>
                      <input
                        v-if="!hasGuias"
                        disabled="disabled"
                        v-model="form.numeroGuia"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="col-md-4" v-else>
                      <label for="nGuia">N° GUIA</label>
                      <v-select
                        :class="[
                          $v.formEnvio.guia_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="guia"
                        placeholder="Guia"
                        label="numero"
                        :options="listasForms.guias"
                        @input="selectGuia()"
                        style="font-size: 13px; background-color: #fff"
                        class="form-control form-control-sm p-0"
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                      ></v-select>
                    </div>
                    <div
                      class="col-md-2"
                      v-if="
                        (accion == 'Diligenciar' &&
                          digito_verificacion === null) ||
                          (accion == 'Cambiar' &&
                            digito_verificacion === null &&
                            Object.keys(guia).length > 0)
                      "
                    >
                      <label for="digito_verificacion">DV</label>
                      <input
                        v-model="formEnvio.digito_verificacion"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.digito_verificacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="producto">TIPO PRODUCTO</label>
                      <br />
                      <select
                        class="form-control form-control-sm"
                        id="tipo_prod"
                        v-model="formEnvio.tipo_producto_id"
                        :class="
                          $v.formEnvio.tipo_producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          (accion == 'Show' && estadoGuia !== 6) ||
                            formEnvio.tipo_act == 4
                        "
                        @change="buscarGuiaInput()"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="op in listasForms.tipo_producto"
                          :key="op.id"
                          :value="op.id"
                        >
                          {{ op.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div
                  class="row mb-2"
                  style="font-size: 12px"
                  v-if="accion == 'Cambiar'"
                >
                  <div class="col-sm-12">
                    <label for="motivo">MOTIVO ANULACIÓN:</label>
                    <textarea
                      v-model="formEnvio.motivo_anulacion"
                      placeholder="Descrición del Motivo"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.motivo_anulacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-6">
                    <label for="ciudad_origen">LUGAR DE EXPEDICIÓN</label>
                    <input
                      v-model="form.ciudadOrigen"
                      type="text"
                      style="font-size: 13px"
                      placeholder="Lugar"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="fecha_expedicion1"
                      >FECHA Y HORA DE EXPEDICIÓN</label
                    >
                    <div class="row ml-1">
                      <input
                        type="date"
                        v-model="formEnvio.fecha_expedicion1"
                        style="font-size: 13px"
                        class="form-control form-control-sm col-md-6"
                        @input="validaFechas()"
                        :class="
                          $v.formEnvio.fecha_expedicion1.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <input
                        v-model="formEnvio.hora_expedicion"
                        type="text"
                        @input="validaFechas()"
                        class="form-control form-control-sm col-md-6"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        :class="
                          $v.formEnvio.hora_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="ml-1">
                      <label for="campo_productor"
                        >PLANTA O CAMPO PRODUCTOR</label
                      >
                      <select
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        id="prod_liquid"
                        v-model="formEnvio.producto_liquido_id"
                        @change="selectProductoLiquido()"
                        :class="
                          $v.formEnvio.producto_liquido_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="pl in listasForms.producto_liquido"
                          :key="pl.id"
                          :value="pl.id"
                        >
                          {{ pl.nombre }}
                          {{ pl.codigo ? "- (" + pl.codigo + ")" : "" }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="remision">FACTURA O REMISIÓN N°</label>
                    <input
                      type="text"
                      v-model="formEnvio.remision"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      placeholder="Orden de Cargue"
                      :class="
                        $v.formEnvio.remision.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-8">
                    <label for="fecha_expedicion">DESPACHADO A:</label>
                    <v-select
                      v-if="this.formEnvio.tipo_operacion == 2"
                      v-model="cliente"
                      style="font-size: 13px"
                      class="form-control form-control-sm p-0"
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        $v.formEnvio.despachado_a.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                      ]"
                      placeholder="Clientes"
                      label="razon_social"
                      :options="listasForms.clientes"
                      :filterable="false"
                      @search="buscarClientes"
                      @input="selectCliente"
                    ></v-select>
                    <input
                      v-else
                      type="text"
                      v-model="formEnvio.despachado_a"
                      style="font-size: 13px"
                      placeholder="Despachado A"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.despachado_a.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CÓDIGO</label>
                    <input
                      type="text"
                      v-model="formEnvio.codigo_destino"
                      style="font-size: 13px"
                      placeholder="Codigo Dane"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.codigo_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-8">
                    <label for="direccion_destino">DIRECCIÓN:</label>
                    <input
                      type="text"
                      v-model="formEnvio.direccion_destino"
                      style="font-size: 13px"
                      placeholder="Dirección"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.direccion_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CIUDAD</label>
                    <input
                      type="text"
                      v-model="formEnvio.ciudad_destino"
                      style="font-size: 13px"
                      placeholder="Ciudad"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.ciudad_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div
                    class="form-group col-md-8"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarDatosRegalias'
                      )
                    "
                  >
                    <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                    <input
                      type="text"
                      v-model="formEnvio.conductor"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-8" v-else>
                    <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                    <input
                      type="text"
                      v-model="formEnvio.conductor"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div
                    class="form-group col-md-4"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarDatosRegalias'
                      )
                    "
                  >
                    <label for="campo_productor">CÉDULA</label>
                    <input
                      type="text"
                      v-model="formEnvio.documento_conductor"
                      style="font-size: 13px"
                      placeholder="Cedula Conductor"
                      label="cedula"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-4" v-else>
                    <label for="campo_productor">CÉDULA</label>
                    <input
                      type="text"
                      v-model="formEnvio.documento_conductor"
                      style="font-size: 13px"
                      placeholder="Cedula Conductor"
                      label="cedula"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div
                    class="form-group col-md-6"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarDatosRegalias'
                      )
                    "
                  >
                    <label for="empresa_transportadora"
                      >EMPRESA TRANSPORTADORA</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Transportadoras"
                      label="razon_social"
                      @search="buscarEmpresas"
                      @input="selectempresa()"
                      :options="listasForms.transportadoras"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-6" v-else>
                    <label for="empresa_transportadora"
                      >EMPRESA TRANSPORTADORA</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Transportadoras"
                      label="razon_social"
                      class="form-control form-control-sm p-0"
                      @search="buscarEmpresas"
                      @input="selectempresa()"
                      :options="listasForms.transportadoras"
                      disabled="disabled"
                    >
                    </v-select>
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarDatosRegalias'
                      )
                    "
                  >
                    <label for="cabezote">PLACAS DEL CABEZOTE</label>
                    <input
                      type="text"
                      v-model="formEnvio.vehiculo"
                      style="font-size: 13px"
                      placeholder="Placa Vehículo"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-3" v-else>
                    <label for="cabezote">PLACAS DEL CABEZOTE</label>
                    <input
                      type="text"
                      v-model="formEnvio.vehiculo"
                      style="font-size: 13px"
                      placeholder="Placa Vehículo"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.actualizarDatosRegalias'
                      )
                    "
                  >
                    <label for="remolque">PLACAS DEL TANQUE</label>
                    <input
                      type="text"
                      v-model="formEnvio.remolque"
                      style="font-size: 13px"
                      placeholder="Placa Remolque"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-3" v-else>
                    <label for="remolque">PLACAS DEL TANQUE</label>
                    <input
                      type="text"
                      v-model="formEnvio.remolque"
                      style="font-size: 13px"
                      placeholder="Placa Remolque"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row justify-content-center" style="font-size: 12px">
                  <div class="form-group col-md-6">
                    <label for="lugar_origen">LUGAR DE ORIGEN</label>
                    <strong
                      v-if="sitioOrigen && sitioOrigen.codigo"
                      class="text-success float-right mr-3"
                      >Código: {{ sitioOrigen.codigo }}</strong
                    >
                    <v-select
                      :class="[
                        $v.formEnvio.sitio_origen_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitioOrigen"
                      placeholder="Sitio Origen"
                      label="nombre_sigla"
                      :options="listasForms.sitiosOrigenes"
                      @search="buscarSitioOrigen"
                      @input="selectSitioOrigen()"
                      style="font-size: 13px; background-color: #fff"
                      class="form-control form-control-sm p-0"
                      :disabled="
                        (accion == 'Show' && estadoGuia !== 6) ||
                          formEnvio.tipo_act == 4
                      "
                    ></v-select>
                    <div class="error" v-if="!formEnvio.sitio_origen_id">
                      Seleccione sitio de origen
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="fecha_cargue">FECHA</label>
                    <input
                      v-model="formEnvio.fecha_cargue"
                      type="date"
                      @input="validaFechas()"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="hora_cargue">HORA DE SALIDA</label>
                    <input
                      v-model="formEnvio.hora_cargue"
                      type="text"
                      @input="validaFechas()"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.formEnvio.hora_cargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @blur="checkFecha()"
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="lugar_destino">LUGAR DE DESTINO</label>
                    <v-select
                      v-model="destino"
                      placeholder="Destino"
                      label="nombre"
                      :options="listasForms.destinos"
                      @search="buscarDestino"
                      @input="selectDestino()"
                      style="font-size: 13px; background-color: #fff"
                      class="form-control form-control-sm p-0"
                      :class="[
                        $v.formEnvio.sitio_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      :disabled="
                        (accion == 'Show' && estadoGuia !== 6) ||
                          formEnvio.tipo_act == 4
                      "
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="lugar_destino">SIGLA DESTINO</label>
                    <input
                      type="text"
                      v-model="formEnvio.sigla_destino"
                      style="font-size: 13px"
                      placeholder="Sigla Destino"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="lugar_destino">CÓDIGO DESTINO</label>
                    <input
                      type="text"
                      v-model="formEnvio.cod_destino"
                      style="font-size: 13px"
                      placeholder="Código Destino"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                    <input
                      v-model="formEnvio.horas_vigencia"
                      :class="
                        $v.formEnvio.horas_vigencia.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      type="text"
                      style="font-size: 13px"
                      placeholder="Horas Vigencia"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="hora_adicional">HORA ADICIONAL</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formEnvio.hora_adicional"
                      :disabled="
                        !formEnvio.horas_vigencia ||
                          (accion == 'Show' && estadoGuia !== 6)
                      "
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="horaAdicional in listasForms.horasAdicionales"
                        :key="horaAdicional.descripcion"
                        :value="horaAdicional.descripcion"
                      >
                        {{ horaAdicional.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="hora_vigencia">RUTA</label>
                    <input
                      v-model="formEnvio.ruta"
                      type="text"
                      style="font-size: 13px"
                      placeholder="Nombre Ruta"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="hora_vigencia">CÓDIGO RUTA</label>
                    <input
                      v-model="formEnvio.codigo_ruta"
                      type="text"
                      style="font-size: 13px"
                      placeholder="Código Ruta"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-1 text-center">
                    <label for>TERCERO</label>
                    <input
                      v-model="terceroSellos"
                      type="checkbox"
                      value="1"
                      class="form-control form-control-sm"
                      @change="buscarSellos()"
                    />
                  </div>
                  <div class="form-group col-md-11">
                    <label for="sellos">DESCRIPCIÓN DEL PRODUCTO</label>
                    <v-select
                      v-model="formEnvio.sellos"
                      label="numero"
                      :options="listasForms.sellos"
                      style="font-size: 13px; background-color: #fff"
                      @input="buscarSellos()"
                      placeholder="Sellos"
                      class="form-control form-control-sm p-0"
                      :class="[
                        $v.formEnvio.sellos.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      multiple
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    ></v-select>
                    <div
                      class="error"
                      v-if="formEnvio.sellos && formEnvio.sellos.length == 0"
                    >
                      Seleccione los Sellos
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gov">GOV</label>
                    <div class="input-group">
                      <input
                        type="number"
                        v-model="formEnvio.gov"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        @blur="validarVolumenes"
                        step="any"
                        :class="
                          $v.formEnvio.gov.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                        id="gov"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gsv">GSV</label>
                    <div class="input">
                      <input
                        type="number"
                        v-model="formEnvio.gsv"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        @blur="validarVolumenes"
                        step="any"
                        :class="
                          $v.formEnvio.gsv.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                      />
                    </div>
                    <div class="error">
                      <b>Máximos barriles: {{ max_barriles }}</b>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="nsv">NSV</label>
                    <input
                      type="number"
                      v-model="formEnvio.nsv"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarVolumenes"
                      step="any"
                      :class="
                        $v.formEnvio.nsv.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      v-model="formEnvio.observaciones"
                      cols="30"
                      rows="4"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    ></textarea>
                    <div
                      style="font-size: 13px"
                      class="error"
                      v-if="!$v.formEnvio.observaciones.required"
                    >
                      Ingrese una Observación
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row justify-content-center" style="font-size: 12px">
                  <div class="form-group col-md-3">
                    <label>FACTOR DE TEMPERATURA</label>
                    <input
                      type="number"
                      v-model="formEnvio.factor_temperatura"
                      style="font-size: 13px"
                      min="50"
                      max="250"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.factor_temperatura.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>ANÁLISIS DE LAB (TEMP°)</label>
                    <input
                      type="number"
                      v-model="formEnvio.analisis_laboratorio"
                      style="font-size: 13px"
                      min="50"
                      max="250"
                      class="form-control form-control-sm"
                      @blur="validarTemp"
                      :class="
                        $v.formEnvio.analisis_laboratorio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>A.P.I</label>
                    <input
                      type="number"
                      v-model="formEnvio.api"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarApi"
                      :class="
                        $v.formEnvio.api.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>B.S.W</label>
                    <input
                      type="number"
                      v-model="formEnvio.bsw"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarBsw"
                      :class="
                        $v.formEnvio.bsw.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>S%</label>
                    <input
                      type="number"
                      v-model="formEnvio.azufre"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarAzufre"
                      :class="
                        $v.formEnvio.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Presión <small>(PSI)</small> </label>
                    <input
                      type="text"
                      v-model="formEnvio.presion"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.presion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Producto</label>
                    <input
                      type="text"
                      v-model="formEnvio.nProducto"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>C.T.L</label>
                    <input
                      type="text"
                      v-model="formEnvio.ctl"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>% Sedimento</label>
                    <input
                      type="text"
                      v-model="formEnvio.porc_sedimento"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>C.S.W</label>
                    <input
                      type="text"
                      v-model="formEnvio.csw"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>R.V.P</label>
                    <input
                      type="text"
                      v-model="formEnvio.rvp"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Incer. Expandida %</label>
                    <input
                      type="text"
                      v-model="formEnvio.iincer_exp_porc"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Incer. Expandida <small>(bls)</small></label>
                    <input
                      type="text"
                      v-model="formEnvio.iincer_exp_bls"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="form-group col-md-5">
                    <label>Llamado Cargue</label>
                    <div class="row">
                      <div class="col-md-7">
                        <input
                          v-model="formEnvio.fecha_llamado"
                          type="date"
                          class="form-control form-control-sm"
                          :class="
                            $v.formEnvio.fecha_llamado.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 13px"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="accion == 'Show' && estadoGuia !== 6"
                        />
                      </div>
                      <div class="col-md-5">
                        <input
                          v-model="formEnvio.hora_llamado"
                          type="text"
                          class="form-control form-control-sm"
                          :class="
                            $v.formEnvio.hora_llamado.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 13px"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="accion == 'Show' && estadoGuia !== 6"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5">
                    <label>Inicio Cargue</label>
                    <div class="row">
                      <div class="col-md-7">
                        <input
                          v-model="formEnvio.fecha_inicio_cargue"
                          type="date"
                          class="form-control form-control-sm"
                          :class="
                            $v.formEnvio.fecha_inicio_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 13px"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="accion == 'Show' && estadoGuia !== 6"
                        />
                      </div>
                      <div class="col-md-5">
                        <input
                          v-model="formEnvio.hora_inicio_cargue"
                          type="text"
                          class="form-control form-control-sm"
                          :class="
                            $v.formEnvio.hora_inicio_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 13px"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="accion == 'Show' && estadoGuia !== 6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="col-md-12">
                    <label for="novedades">NOVEDADES - PROCESO STAND BY</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="formEnvio.novedades"
                      label="descripcion"
                      :options="listasForms.novedades"
                      style="font-size: 13px; background-color: #fff"
                      placeholder="Novedades"
                      multiple
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    ></v-select>
                    <div class="error" v-if="formEnvio.novedades">
                      Seleccione las novedades
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-2">
                  <button class="btn bg-gray-dark col-md-11" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn bg-navy col-md-11"
                    v-show="!$v.formEnvio.$invalid"
                    @click="saveDetGuia()"
                    :disabled="accion == 'Show' && estadoGuia !== 6"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>
                </div>
                <div class="col-md-2">
                  <a
                    id="botonImp"
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="print()"
                    v-show="
                      accion == 'Show' && (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Guía
                  </a>
                </div>
                <div class="col-md-2">
                  <a
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="cierreManual()"
                    v-show="
                      accion == 'Show' && (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-lock"></i>
                    <br />Cierre Manual
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
import $ from "jquery";
export default {
  name: "DetGuiasRegaliasForm",
  components: {
    vSelect,
    Loading,
  },

  data() {
    return {
      cargando: false,
      det_guia: null,
      form: {
        ciudad_destino: null,
        ciudadOrigen: null,
        numeroGuia: null,
      },
      formEnvio: {
        id: null,
        tipo_producto_id: null,
        ciudad_origen_id: null,
        ciudad_destino: null,
        fecha_expedicion: null,
        fecha_expedicion1: null,
        hora_expedicion: null,
        remision: null,
        despachado_a: null,
        cliente_id: null,
        tipo_vehiculo_id: null,
        transportadora_id: null,
        producto_liquido_id: null,
        codigo_destino: null,
        sigla_destino: null,
        cod_destino: null,
        direccion_destino: null,
        sitio_origen_id: null,
        sitio_destino: null,
        sitio_id: null,
        sitio_type: null,
        guia_id: null,
        hora_cargue: null,
        horas_vigencia: null,
        hora_adicional: null,
        novedades: [],
        sellos: [],
        gov: null,
        gsv: null,
        nsv: null,
        api: null,
        bsw: null,
        azufre: null,
        factor_temperatura: null,
        analisis_laboratorio: null,
        presion: null,
        producto: null,
        nProducto: null,
        ctl: null,
        porc_sedimento: null,
        csw: null,
        rvp: null,
        iincer_exp_porc: null,
        iincer_exp_bls: null,
        fecha_llamado: null,
        hora_llamado: null,
        fecha_hora_llamado: null,
        fecha_inicio_cargue: null,
        hora_inicio_cargue: null,
        fecha_hora_inicio_cargue: null,
        numeroGuia: null,
        user_id: null,
        tipo_operacion: null,
        estado: null,
        digito_verificacion: null,
        motivo_anulacion: null,
        observaciones: null,
      },
      guia: [],
      volver_viaje: 0,
      det_guias: [],
      estadoGuia: null,
      sitioOrigen: [],
      sitio_type: null,
      sitio_id: null,
      tipo_control: null,
      productoLiquido: [],
      cliente: [],
      transportadora: [],
      destino: {},
      accion: null,
      empresa: null,
      horaCargue: null,
      fechaCargue: null,
      actoGuia: null,
      hasGuias: false,
      listasForms: {
        sitiosOrigenes: [],
        novedades: [],
        clientes: [],
        sellos: [],
        guias: [],
        empresas: [],
        tipos_productos: [],
        horasAdicionales: [],
        tipo_producto: [],
        producto_liquido: [],
        transportadoras: [],
        destinos: [],
        despachados: [],
      },
      estadoFecha: null,
      modalCalc: false,
      tercero: 0,
      terceroSellos: 0,
      max_barriles: 0,
      acto: "",
      digito_verificacion: null,
    };
  },

  validations() {
    let dv = {};
    let motivo = {};
    if (this.digito_verificacion == null) {
      dv = {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1),
        numeric,
      };
    } else {
      dv = {
        required: false,
      };
    }

    if (this.accion == "Cambiar") {
      motivo = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(199),
      };
    } else {
      motivo = {
        required: false,
      };
    }

    return {
      formEnvio: {
        digito_verificacion: dv,
        fecha_expedicion1: {
          required,
        },
        hora_expedicion: {
          required,
          timeFormat,
        },
        remision: {
          required,
        },
        tipo_producto_id: {
          required,
        },
        codigo_destino: {
          required,
        },
        despachado_a: {
          required,
        },
        ciudad_destino: {
          required,
        },
        direccion_destino: {
          required,
        },
        sitio_origen_id: {
          required,
        },
        sitio_destino: {
          required,
        },
        /* sigla_destino: {
          required,
        }, */
        /* cod_destino: {
          required,
        }, */
        /* ruta: {
          required,
        }, */
        /*  codigo_ruta: {
          required,
        }, */
        horas_vigencia: {
          required,
        },
        producto_liquido_id: {
          required,
        },
        guia_id: {
          required,
        },
        novedades: {
          required,
        },
        sellos: {
          required,
        },
        fecha_cargue: {
          required,
        },
        hora_cargue: {
          required,
          timeFormat,
        },
        analisis_laboratorio: {
          required,
        },
        factor_temperatura: {
          required,
        },
        gov: {
          required,
        },
        gsv: {
          required,
        },
        nsv: {
          required,
        },
        api: {
          required,
        },
        bsw: {
          required,
        },
        azufre: {
          required,
        },
        presion: {
          required,
        },
        fecha_llamado: {
          required,
        },
        hora_llamado: {
          required,
        },
        fecha_inicio_cargue: {
          required,
        },
        hora_inicio_cargue: {
          required,
        },
        observaciones: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(199),
        },
        motivo_anulacion: motivo,
      },
    };
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      if (this.$route.params.volver_viaje !== "undefined") {
        this.volver_viaje = 1;
      }
      if (this.$route.params.accion == "Diligenciar") {
        this.form = this.$route.params.data_edit;
        this.acto = 1;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.accion = 2;
        this.formEnvio.tipo_producto_id = this.form.tipo_producto_id;
        this.formEnvio.user_id = this.$store.getters.getUser.id;
        this.formEnvio.id = this.form.id;
        this.formEnvio.sitio_id = this.form.sitio_id;
        this.formEnvio.sitio_type = this.form.sitio_type;
        this.formEnvio.tipo_vehiculo_id = this.form.tipo_vehiculo_id;
        this.formEnvio.tipo_operacion = this.form.tipo_operacion;
        this.transportadora = {
          id: this.form.transportadora.id,
          razon_social: this.form.transportadora.razon_social,
        };

        this.formEnvio.transportadora_id = this.transportadora.id;
        this.formEnvio.estado = this.form.estado;
        await this.getTipoProductos();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        this.sitioOrigen = this.form.sitio_origen;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        this.formEnvio.sitio_origen_id = this.form.sitio_origen.id;
        this.formEnvio.conductor = this.form.conductor;
        this.formEnvio.documento_conductor = this.form.documento_conductor;
        this.formEnvio.remolque = this.form.remolque;
        this.formEnvio.vehiculo = this.form.vehiculo;
        this.formEnvio.fecha_cargue = moment().format("YYYY-MM-DD");
        this.formEnvio.hora_cargue = moment().format("HH:mm:ss");
        this.form.ciudadOrigen = this.form.sitio_origen.ciudad.nombre;
        this.formEnvio.ciudad_origen_id = this.form.sitio_origen.ciudad.id;
        this.formEnvio.producto_liquido_id = this.productoLiquido.id;
        this.formEnvio.horas_vigencia = this.form.vigencia_guia
          ? this.form.vigencia_guia
          : null;
        this.formEnvio.observaciones = this.form.observaciones
          ? this.form.observaciones
          : null;
        this.max_barriles = this.$route.params.data_edit.volumen_calculado;
        this.cargando = false;
      } else if (this.$route.params.accion == "Show") {
        this.formEnvio = this.$route.params.data_edit;
        this.acto = 2;
        this.estadoGuia = this.formEnvio.guia.estado;
        this.form.id = this.$route.params.data_edit.id;
        this.form.numeroGuia =
          this.formEnvio.guia.numero +
          "-" +
          this.formEnvio.guia.digito_verificacion;

        this.formEnvio.digito_verificacion = this.formEnvio.guia.digito_verificacion;
        await this.getHorasAdicionales();
        await this.getTipoProductos();
        await this.getNovedades();
        await this.buscarDestino();
        this.sitioOrigen = this.formEnvio.sitio_origen;
        this.destino = this.listasForms.destinos.find(
          (d) => d.nombre === this.formEnvio.sitio_destino
        );
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        this.listasForms.producto_liquido[0] = {
          id: this.formEnvio.producto_liquido.id,
          nombre: this.formEnvio.producto_liquido.nombre,
        };
        this.transportadora = {
          id: this.formEnvio.transportadora_id,
          razon_social: this.formEnvio.transportadora.razon_social,
        };

        this.formEnvio.horas_vigencia =
          this.formEnvio.horas_vigencia + " Horas";
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.formEnvio.hora_expedicion = hora;
        this.form.ciudadOrigen = this.formEnvio.ciudad_origen.nombre;
        this.cargando = false;
      } else if (this.$route.params.accion == "Cambiar") {
        let viaje = this.$route.params.data_edit;
        this.acto = 3;
        this.formEnvio = viaje;
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.formEnvio.hora_expedicion = hora;
        this.formEnvio.sellos = viaje.guia.det_guias_regalias[0].sellos;
        this.form.sitio_origen_id = viaje.sitio_origen.id;
        this.sitioOrigen = viaje.sitio_origen;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        this.form.ciudadOrigen = viaje.sitio_origen.ciudad.nombre;
        this.transportadora = viaje.transportadora;

        /* this.form.sitio_origen_id = viaje.ruta.origen.id;
        this.form.ciudadOrigen = viaje.ruta.origen.ciudad.nombre; */
        this.max_barriles = viaje.volumen_calculado;
        this.form.id = viaje.id;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        await this.buscarDestino();
        this.destino = this.listasForms.destinos.find(
          (d) => d.nombre === this.formEnvio.sitio_destino
        );
        this.formEnvio.tipo_act = 3;
        this.formEnvio.accion = 2;
        this.cargando = false;
        /* this.sitioOrigen = viaje.ruta.origen; */
        /* this.formEnvio.tipo_vehiculo_id = this.vehiculo.tipo_vehiculo_id; */
      } else if (this.$route.params.accion == "Actualizar") {
        let viaje = this.$route.params.data_edit;
        let guia = this.$route.params.data_edit.guia;
        this.formEnvio = guia.det_guias_regalias[0];
        this.acto = 2;
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.hora_expedicion = hora;
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.form.sitio_origen_id = viaje.sitio_origen.id;
        this.form.ciudadOrigen = viaje.sitio_origen.ciudad.nombre;
        this.form.numeroGuia = guia.numero + "-" + guia.digito_verificacion;
        this.formEnvio.tipo_act = 4;
        this.formEnvio.accion = 2;
        this.form.id = viaje.id;
        this.transportadora = viaje.transportadora;
        this.cliente = this.formEnvio.cliente;
        this.max_barriles = viaje.volumen_calculado;
        await this.buscarDVActGuia();

        /* this.formEnvio.tipo_vehiculo_id = viaje.vehiculo.tipo_vehiculo_id; */
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarSellos();
        await this.buscarDestino();
        this.destino = this.listasForms.destinos.find(
          (d) => d.nombre === this.formEnvio.sitio_destino
        );
        this.sitioOrigen = viaje.sitio_origen;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
      } else {
        this.cargando = false;
        this.id = this.$route.params.id;
      }

      // Se llenan los datos relacionados con la liquidacion de la guia
      if (
        this.$route.params &&
        this.$route.params.data_edit &&
        this.$route.params.data_edit.liquidacion_guia
      ) {
        const liq = this.$route.params.data_edit.liquidacion_guia;
        this.max_barriles = this.$route.params.data_edit.volumen_calculado;
        this.formEnvio.gov = liq.gov;
        this.formEnvio.gsv = liq.gsv;
        this.formEnvio.nsv = liq.nsv;
        this.formEnvio.analisis_laboratorio = liq.temperatura;
        this.formEnvio.api = liq.api;
        this.formEnvio.bsw = liq.bsw;
        this.formEnvio.azufre = liq.azufre;

        this.formEnvio.presion = liq.presion;
        this.formEnvio.producto = liq.producto;
        this.formEnvio.nProducto = liq.nProducto;
        this.formEnvio.ctl = liq.ctl;
        this.formEnvio.porc_sedimento = liq.porc_sedimento;
        this.formEnvio.csw = liq.csw;
        this.formEnvio.rvp = liq.rvp;
        this.formEnvio.iincer_exp_porc = liq.iincer_exp_porc;
        this.formEnvio.iincer_exp_bls = liq.iincer_exp_bls;

        await this.validarTemp();
        await this.validarBsw();
        await this.validarAzufre();
        await this.validarApi();
      } else {
        /* this.formEnvio.presion = "Sin Liquidacion"; */
        this.formEnvio.nProducto = "Sin Liquidacion";
        this.formEnvio.ctl = "Sin Liquidacion";
        this.formEnvio.porc_sedimento = "Sin Liquidacion";
        this.formEnvio.csw = "Sin Liquidacion";
        this.formEnvio.rvp = "Sin Liquidacion";
        this.formEnvio.iincer_exp_porc = "Sin Liquidacion";
        this.formEnvio.iincer_exp_bls = "Sin Liquidacion";
      }

      // Se llenan los datos relacionados con las fechas de llamado e inicio del cargue
      if (
        this.$route.params &&
        this.$route.params.data_edit &&
        this.$route.params.data_edit.fecha_hora_llamado
      ) {
        const llamado = moment(this.$route.params.data_edit.fecha_hora_llamado);
        this.formEnvio.fecha_hora_llamado = llamado.format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.formEnvio.fecha_llamado = llamado.format("YYYY-MM-DD");
        this.formEnvio.hora_llamado = llamado.format("HH:mm:ss");
      }

      if (
        this.$route.params &&
        this.$route.params.data_edit &&
        this.$route.params.data_edit.fecha_hora_inicio_cargue
      ) {
        const inicio_cargue = moment(
          this.$route.params.data_edit.fecha_hora_inicio_cargue
        );
        this.formEnvio.fecha_hora_inicio_cargue = inicio_cargue.format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.formEnvio.fecha_inicio_cargue = inicio_cargue.format("YYYY-MM-DD");
        this.formEnvio.hora_inicio_cargue = inicio_cargue.format("HH:mm:ss");
      }
    },

    validarTemp() {
      if (
        this.formEnvio.analisis_laboratorio > 250 ||
        this.formEnvio.analisis_laboratorio < 50
      ) {
        this.$swal({
          text:
            "La temperatura debe estar entre 50°C y 250°C. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.analisis_laboratorio = null;
      }
    },

    validarApi() {
      if (this.formEnvio.api > 95 || this.formEnvio.api < 6) {
        this.$swal({
          text:
            "El A.P.I debe estar entre 6 y 95. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.api = null;
      }
    },

    validarBsw() {
      if (this.formEnvio.bsw > 20 || this.formEnvio.bsw < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de B.S.W debe estar entre 0 y 20. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.bsw = null;
      }
    },

    validarAzufre() {
      if (this.formEnvio.azufre > 100 || this.formEnvio.azufre < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de Azufre debe estar entre 0 y 100. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.azufre = null;
      }
    },

    validarVolumenes() {
      if (this.formEnvio.gsv != null) {
        if (this.formEnvio.gov > 300 || this.formEnvio.gov < 0) {
          this.$swal({
            text:
              "El valor del GOV no puede ser mayor a 300. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gov = null;
        }
        if (this.formEnvio.gsv > this.formEnvio.gov) {
          this.$swal({
            text:
              "El valor del GOV no puede ser menor al GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gov = null;
          this.formEnvio.gsv = null;
        }
        if (this.formEnvio.gsv > this.max_barriles) {
          this.$swal({
            text:
              "El valor de GSV no puede superar " +
              this.max_barriles +
              " barriles",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gsv = null;
        }
      }

      if (this.formEnvio.gsv != null && this.formEnvio.gov != null) {
        if (this.formEnvio.gsv >= this.formEnvio.gov) {
          this.$swal({
            text:
              "El valor de GSV no puede ser mayor o igual al valor de GOV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gsv = null;
          this.formEnvio.gov = null;
        }
      }

      if (this.formEnvio.gsv != null && this.formEnvio.nsv != null) {
        if (this.formEnvio.nsv > this.formEnvio.gsv) {
          this.$swal({
            text:
              "El valor de NSV no puede ser mayor al valor de GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.nsv = null;
          this.formEnvio.gsv = null;
        }
      }
    },

    cierreManual() {
      this.cargando = true;
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/detalleGuiasRegalias/cierreManual",
        data: this.formEnvio,
      })
        .then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se realizó el cambio de estado exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          const sitioID = this.sitio_id;
          const sitioType = this.sitio_type;
          const tipoControl = this.tipo_control;
          this.$router.replace({
            path: `/Hidrocarburos/VentanasOperaciones/${sitioID}/${sitioType}/${tipoControl}`,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoProductos() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.cargando = false;
        this.listasForms.tipo_producto = response.data;
      });
    },

    getProductosLiquidos() {
      axios
        .get(
          "/api/hidrocarburos/detalleGuiasRegalias/listaProductoLiqui?sitio_id=" +
            this.form.sitio_origen_id +
            "&sitio_type=" +
            this.$route.params.sitio_type
        )
        .then((response) => {
          this.listasForms.producto_liquido =
            response.data[0].productos_liquidos;
        });
    },

    checkFecha() {
      this.estadoFecha = null;
      if (
        this.formEnvio.fecha_expedicion.slice(0, 10) >
        this.formEnvio.fecha_cargue
      ) {
        this.$swal({
          title: "Revisar Fechas!!",
          text:
            "La fecha de cargue no puede ser menor a la fecha de expedición",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.estadoFecha = 1;
      } else {
        if (
          this.formEnvio.fecha_expedicion.slice(11, 19) >
          this.formEnvio.hora_cargue
        ) {
          this.$swal({
            title: "Revisar Horas!!",
            text:
              "La hora de cargue no puede ser menor a la hora de expedición",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.estadoFecha = 2;
        }
      }
    },

    // funcion que valida fecha de expedicion y fecha final
    validaFechas() {
      if (
        this.formEnvio.fecha_expedicion1 &&
        this.formEnvio.fecha_cargue &&
        this.formEnvio.hora_expedicion &&
        this.formEnvio.hora_cargue
      ) {
        var fecha_menor = new Date(
          this.formEnvio.fecha_expedicion1 +
            " " +
            this.formEnvio.hora_expedicion
        );
        var fecha_mayor = new Date(
          this.formEnvio.fecha_cargue + " " + this.formEnvio.hora_cargue
        );
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.formEnvio.fecha_expedicion1 = null;
          this.formEnvio.hora_expedicion = null;
          this.$swal({
            icon: "error",
            title: `La fecha y hora de expedicion no puede ser mayor a la fecha y hora de salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasLlamadoCargue() {
      if (
        this.formEnvio.fecha_llamado &&
        this.formEnvio.hora_llamado &&
        this.formEnvio.fecha_inicio_cargue &&
        this.formEnvio.hora_inicio_cargue
      ) {
        var fecha_menor = new Date(
          this.formEnvio.fecha_llamado + " " + this.formEnvio.hora_llamado
        );
        var fecha_mayor = new Date(
          this.formEnvio.fecha_inicio_cargue +
            " " +
            this.formEnvio.hora_inicio_cargue
        );
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de llamado de cargue no puede ser mayor a la fecha y hora de inicio del cargue...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formEnvio.fecha_inicio_cargue = null;
          this.formEnvio.hora_inicio_cargue = null;
        }
      }

      if (this.formEnvio.fecha_llamado && this.formEnvio.hora_llamado) {
        var fecha_enturne = new Date(this.form.fecha_enturne_cargue);
        var fecha_llamado = new Date(
          this.formEnvio.fecha_llamado + " " + this.formEnvio.hora_llamado
        );

        if (fecha_enturne > fecha_llamado) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de llamado de cargue no puede ser menor a la fecha de enturne...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formEnvio.fecha_llamado = null;
          this.formEnvio.hora_llamado = null;
        }
      }
    },

    //------------------------------------------------------------------------------------
    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    buscarSellos() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      let tipo_empresa = 1;

      if (me.terceroSellos == true) {
        tipo_empresa = 2;
      }
      axios
        .get(url, {
          params: {
            tipo_empresa,
            sitio_id: me.form.sitio_origen_id,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sellos = respuesta;
          me.formEnvio.sello_id = me.listasForms.sellos.id;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getHorasAdicionales() {
      axios.get("/api/lista/55").then((response) => {
        this.listasForms.horasAdicionales = response.data;
      });
    },

    async saveDetGuia() {
      var confirm = true;
      if (this.formEnvio.tipo_act !== undefined) {
        if (this.formEnvio.tipo_act == 3) {
          confirm = false;
          await this.$swal({
            title: "Esta seguro de anular y cambiar la guia?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Anular y cambiar!",
          }).then((result) => {
            if (result.value) {
              confirm = true;
            }
          });
        }
      }
      if (confirm) {
        this.formEnvio.viaje_id = this.form.id;
        if (!this.$v.formEnvio.$invalid) {
          if (!this.formEnvio.hora_adicional && this.formEnvio.tipo_act != 4) {
            await this.$swal({
              title: "Hora Adicional",
              text: "Esta seguro de que no desea asignar horas adicionales?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Aceptar!",
            }).then(async (result) => {
              if (result.value) {
                if (this.estadoFecha == 1 || this.estadoFecha == 2) {
                  await this.$swal({
                    title: "Revisar Fechas!!",
                    text:
                      "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar!",
                  }).then(async (result) => {
                    if (result.value) {
                      await this.save();
                    }
                  });
                } else {
                  await this.save();
                }
              }
            });
          } else {
            if (this.estadoFecha == 1 || this.estadoFecha == 2) {
              await this.$swal({
                title: "Revisar Fechas!!",
                text:
                  "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar!",
              }).then(async (result) => {
                if (result.value) {
                  await this.save();
                }
              });
            } else {
              await this.save();
            }
          }
        }
      }
    },

    async save() {
      let guia = null;
      if (this.formEnvio.tipo_act !== 4) {
        this.estadoGuia = 6;
      }
      this.formEnvio.fecha_expedicion =
        this.formEnvio.fecha_expedicion1 + " " + this.formEnvio.hora_expedicion;
      if (this.digito_verificacion != null) {
        guia = this.formEnvio.numeroGuia;
      } else {
        guia = this.formEnvio.numeroGuia + this.formEnvio.digito_verificacion;
      }
      this.formEnvio.numeroGuia = guia;

      this.formEnvio.fecha_hora_llamado =
        this.formEnvio.fecha_llamado && this.formEnvio.hora_llamado
          ? `${this.formEnvio.fecha_llamado} ${this.formEnvio.hora_llamado}`
          : null;

      this.formEnvio.fecha_hora_inicio_cargue =
        this.formEnvio.fecha_inicio_cargue && this.formEnvio.hora_inicio_cargue
          ? `${this.formEnvio.fecha_inicio_cargue} ${this.formEnvio.hora_inicio_cargue}`
          : null;

      await axios({
        method: "POST",
        url: "/api/hidrocarburos/detalleGuiasRegalias",
        data: this.formEnvio,
      })
        .then(async (response) => {
          if (this.formEnvio.tipo_act !== 4) {
            this.$route.params.accion = "Show";
            this.$route.params.data_edit = response.data;
          }
          await this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          await this.init();
        })
        .catch(async (e) => {
          let res = await Object.assign({}, e);
          let err = res.response.data.msg;
          await this.$swal({
            icon: "error",
            title: "Ha ocurrido un error - " + err,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    print() {
      $("#botonImp")
        .attr(
          "href",
          process.env.VUE_APP_API_URL +
            "/toPrint/guiaRegalias/" +
            this.formEnvio.id
        )
        .attr("target", "_blank");
      this.estadoGuia = 2;
    },

    buscarSitioOrigen(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitiosOrigenes = [];
            response.data.forEach((r) => {
              me.listasForms.sitiosOrigenes.push({
                id: r.id,
                nombre: r.nombre,
                nombre_sigla: r.sigla ? `${r.nombre} - (${r.sigla})` : r.nombre,
                codigo: r.codigo,
                ciudad: r.ciudad,
              });
            });
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async buscarDestino(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/destinosEcopetrol/lista?destino=" + search;
        await axios
          .get(url)
          .then(function(response) {
            me.listasForms.destinos = response.data;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarGuiaVselect(search) {
      if (search != "") {
        let me = this;
        var url =
          "api/hidrocarburos/guias/lista?guia=" +
          search +
          "&sitio_id=" +
          this.form.sitio_origen_id;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.guias = respuesta;
            me.formEnvio.guia_id = me.listasForms.guias.id;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarGuiaInput() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/guias/lista";
      let tipo_empresa = 1;
      let anulacion = 0;

      if (this.acto == 3) {
        anulacion = 1;
      }

      if (me.tercero == true) {
        tipo_empresa = 2;
      }
      axios
        .get(url, {
          params: {
            sitio_id: me.form.sitio_origen_id,
            tipo_empresa,
            tipo_producto_id: me.formEnvio.tipo_producto_id,
            anulacion: anulacion,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.form.numeroGuia = null;
          me.formEnvio.numeroGuia = null;
          if (anulacion) {
            me.listasForms.guias = respuesta;
          } else {
            if (respuesta.numero == undefined) {
              me.$swal({
                text: "No se encontro ninguna guia en el stock",
                icon: "error",
                confirmButtonText: "Aceptar!",
              });
            } else {
              if (respuesta.digito_verificacion != null) {
                me.form.numeroGuia =
                  respuesta.numero + "-" + respuesta.digito_verificacion;
                me.formEnvio.numeroGuia =
                  respuesta.numero + respuesta.digito_verificacion;
                me.digito_verificacion = respuesta.digito_verificacion;
              } else {
                me.form.numeroGuia = respuesta.numero;
                me.formEnvio.numeroGuia = respuesta.numero;
              }
              me.formEnvio.guia_id = respuesta.id;
              me.estadoGuia = respuesta.estado;
            }
          }
          me.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarDVActGuia() {
      let g = this.form.numeroGuia.split("-");
      if (g.length > 0) {
        this.digito_verificacion = g[1];
      } else {
        this.digito_verificacion = null;
      }
    },

    selectGuia() {
      if (this.guia) {
        this.formEnvio.guia_id = this.guia.id;
        this.formEnvio.numeroGuia = this.guia.digito_verificacion
          ? this.guia.numero + this.guia.digito_verificacion
          : this.guia.numero;
        this.digito_verificacion = this.guia.digito_verificacion;
        this.estadoGuia = this.guia.estado;
      } else {
        this.formEnvio.guia_id = null;
        this.formEnvio.numeroGuia = null;
        this.estadoGuia = null;
      }
    },

    selectSitioOrigen() {
      // Se reinician las variables a modificar del formulario
      this.form.ciudadOrigen = null;
      this.formEnvio.sitio_origen_id = null;
      this.listasForms.producto_liquido = [];
      this.formEnvio.producto_liquido_id = null;

      if (this.sitioOrigen) {
        this.formEnvio.sitio_origen_id = this.sitioOrigen.id;
        this.form.sitio_origen_id = this.sitioOrigen.id;
        this.form.ciudadOrigen = this.sitioOrigen.ciudad.nombre;
        this.getProductosLiquidos();
        if (this.formEnvio.tipo_act !== 4) {
          this.buscarGuiaInput();
          this.buscarSellos();
        }
      }
    },

    selectDestino() {
      this.formEnvio.sitio_destino = null;
      this.formEnvio.codigo_destino = null;
      this.formEnvio.despachado_a = null;
      this.formEnvio.direccion_destino = null;
      this.formEnvio.ciudad_destino = null;

      if (this.destino) {
        let despachado_a = this.listasForms.despachados.find(
          (d) => d.numeracion === this.destino.despachado_a
        );
        this.formEnvio.sitio_destino = this.destino.nombre;
        this.formEnvio.codigo_destino = this.destino.ciudad
          ? this.destino.ciudad.cod_dane
          : null;
        this.formEnvio.despachado_a = despachado_a
          ? despachado_a.descripcion
          : null;
        this.formEnvio.direccion_destino = this.destino.direccion;
        this.formEnvio.ciudad_destino = this.destino.ciudad.nombre;
      }
    },

    selectProductoLiquido() {
      let prod_liqui = this.listasForms.producto_liquido.find(
        (n) => n.id === this.formEnvio.producto_liquido_id
      );
    },

    selectempresa() {
      this.formEnvio.transportadora_id = "";
      if (this.transportadora) {
        this.formEnvio.transportadora_id = this.transportadora.id;
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.transportadoras = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarClientes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/com/clientes/lista?razon_social=" + search;
        axios
          .get(url, {
            params: { linea_negocio_id: 11 },
          })
          .then(function(response) {
            me.listasForms.clientes = response.data;
            loading(false);
          })
          .catch(function(error) {
            loading(false);
            me.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectCliente() {
      this.formEnvio.cliente_id = null;
      this.formEnvio.despachado_a = null;
      if (this.cliente) {
        this.formEnvio.cliente_id = this.cliente.id;
        this.formEnvio.despachado_a = this.cliente.razon_social;
      }
    },

    async getDespachado() {
      await axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados = response.data;
      });
    },

    back() {
      if (
        this.$route.params.actoGuia == 2 ||
        this.$route.params.actoGuia == 3
      ) {
        return this.$router.replace({
          path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.sitio_type}/${this.tipo_control}`,
        });
      } else {
        return this.$router.replace("/Hidrocarburos/Viajes");
      }
    },
  },

  async beforeMount() {
    this.cargando = true;
    await this.init();
    await this.getDespachado();
  },
};
</script>
